/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { TableAction } from "./table-neogen";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function OptionsDropDown(props: OptionsDropDownProps) {
    return (
        <div className="relative">
            <Menu as="div" className="static inline-block text-left left-0">
                <div>
                    {props.asEllipses ? (
                        <Menu.Button className="inline-flex justify-center w-full ">
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                    ) : (
                        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-indigo-500">
                            Options
                            <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                        </Menu.Button>
                    )}
                </div>

                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-left z-10 absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none dark:border-gray-600 dark:border">
                        <div className="py-1">
                            {props.options.map((option, idx: number) => {
                                // if (option?.onClick) {
                                //     option.action = option.onClick;
                                // }
                                return (
                                    <Menu.Item key={idx}>
                                        {({ active }) => (
                                            <a
                                                href="#"
                                                onClick={() => option?.action()}
                                                className={classNames(
                                                    active
                                                        ? "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-700"
                                                        : "text-gray-700 dark:text-gray-400",
                                                    "group flex items-center px-4 py-2 text-sm",
                                                )}
                                            >
                                                <span
                                                    className={
                                                        "fa-fw mr-3 text-gray-400 dark:text-green-500 " + option?.icon
                                                    }
                                                />
                                                {option?.label}
                                            </a>
                                        )}
                                    </Menu.Item>
                                );
                            })}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

type Option = {
    label: string;
    action?: any;
    onClick?: (id: any) => void;
    icon?: string;
};

type OptionsDropDownProps = {
    options: (Option | null)[];
    asEllipses?: boolean;
};
