import { lazy, Suspense, useCallback, useEffect, useLayoutEffect, useState } from "react";
import * as React from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import "./App.css";
import ProcessFlowAdmin from "./sections/processflows/processflow";
import Login from "./sections/users/login";
import Logout from "./sections/users/logout";
import Loader2 from "./sections/utilities/Loader2";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { QueryClient, QueryClientProvider, useQuery } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import SideMenu from "./sections/menu/menu";
import ProcessFlowWizard from "./sections/processflows/processflow-wizard";
import Users from "./sections/admin/users/users";
import Companies from "./sections/companies/companies";
import Roles from "./sections/admin/roles/roles";
import ProcessFlowGroup from "./sections/processflows/processflow-group";
import Affiliates from "./sections/affiliates/affiliates";
import Clients from "./sections/clients/clients";
import authService from "./services/auth.service";
import RoleGroups from "./sections/admin/roles/role-groups";
import UserContext from "./services/user-context";
import Files from "./sections/admin/files/files";
import Settings from "./sections/settings/settings";
import MenuAdmin from "./sections/menu/menu-admin";
import Application from "./sections/applications/application";
import NewApplication from "./sections/applications/new-application";
import DashboardHeader from "./sections/dashboard/dashboard-header";
import Faq from "./sections/help/faq";
import AddFaq from "./sections/help/modals/add-faq";
import Help from "./sections/help/help";
import ListProducts from "./sections/business-rules/list-products";
import Product from "./sections/business-rules/product";
import DefaultUsers from "./sections/admin/users/default-users";
import SignUp from "./sections/users/sign-up";
import DashboardTop from "./sections/dashboard/dashboard-top";
import { RecoilRoot, useRecoilState } from "recoil";
import ErrorBoundary from "./error-boundary";
import MapDemo from "./sections/admin/map";
import Testing from "./sections/processflows/testing";
import { VersionPage } from "./sections/version";
import { Wizard } from "./jason-proof-of-concept/wizard/wizard";
import { WizardContainer } from "./jason-proof-of-concept/wizard/wizard-container";
import { ClickToComponent } from "click-to-react-component";
import { SetupAccountPage } from "./jason-proof-of-concept/users/pages/setup-account-page";
import UTM from "./sections/utm/utm";
import Loader3 from "./sections/utilities/Loader3";
import MyFiles from "./sections/my-files/my-files";
import UTMLogs from "./sections/utm/logs";
import ForgotPassword from "./sections/users/forgot-password";
import ResetPassword from "./sections/users/reset-password";
import CustomerSummary from "./sections/users/customer-summary";
import Messages from "./sections/messages/messages";
import MessageTypes from "./sections/messages/message-types";
import CustomerCard from "./sections/users/customer-card";
import CustomerCard2 from "./sections/users/customer-card2";
import ScrollToTop from "./hooks/scrollTop";
import { BillingPage } from "./billing/billingPage";
import { UserApplicationPage } from "./sections/admin/users/user-application-page";
import { MagicLinkPage } from "./magic-links/pages/magic-link-page";
import { I18NextPage } from "./sections/i18next/i18next-page";
const Dashboard = lazy(() => import("./sections/dashboard/dashboard"));

const localStoragePersister = createSyncStoragePersister({ storage: window.localStorage });

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 1000 * 60 * 60 * 24, // 24 hours
            staleTime: 1000 * 10, // 10 seconds
            refetchOnWindowFocus: false,
        },
    },
});

persistQueryClient({
    queryClient,
    persister: localStoragePersister,
});

const Wrapper = ({ children }: { children: any }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        // alert("about to scroll to top");
        setTimeout(() => {
            // alert("scrolling to top");
            window.scroll(0, 0);
            document.querySelector("body")?.scrollTo(0, 0);
        }, 10000);
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children;
};

function App(): JSX.Element {
    // const [amInIframe, setAmInIframe] = useState(false);
    // const [searchParams, setSearchParams] = useSearchParams();

    const inIframe = useCallback(() => {
        if (window.location.search === "?inIframe=1") {
            return true;
        }
        try {
            document.body.classList.add("iframe");
            return window.self !== window.top;
        } catch (e) {
            document.body.classList.add("inIframe");
            return true;
        }
    }, []);

    const changeDarkMode = useCallback(
        (e: any) => {
            if (inIframe()) {
                // setAmInIframe(true);
                setDarkMode(false);
                document.body.classList.add("inIframe");
                document.body.classList.add("bg-white");
                document.body.classList.remove("bg-slate-300");
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
            }
            setDarkMode(e.matches);
            localStorage.setItem("theme", e.matches ? "dark" : "light");
        },
        [inIframe],
    );

    const getDarkMode = useCallback(() => {
        const currentLocalStorageTheme = inIframe() ? "light" : localStorage.getItem("theme");
        if (currentLocalStorageTheme !== null) {
            // console.error("Theme found in local storage: ", currentLocalStorageTheme);
            if (currentLocalStorageTheme === "dark") {
                // console.error("Setting dark mode");
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");

                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                return false;
            }
        } else {
            // Couldn't find theme in local storage
            // console.error("Theme not stored");
            if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
                document.body.classList.add("dark-theme");
                document.body.classList.add("dark");
                localStorage.setItem("theme", "dark");
                return true;
            } else {
                document.body.classList.remove("dark-theme");
                document.body.classList.remove("dark");
                localStorage.setItem("theme", "light");
                return false;
            }
        }
    }, [inIframe]);

    useEffect(() => {
        const dm = getDarkMode();
        setDarkMode(dm);
        if (!dm) {
            document.body.classList.remove("bg-slate-300");
        } else {
            document.body.classList.add("bg-slate-300");
        }
    }, [getDarkMode]);

    useEffect(() => {
        // Add listener to update styles
        window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", changeDarkMode);

        // Setup dark/light mode for the first time
        // onSelectMode(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
        // setDarkMode(window.matchMedia("(prefers-color-scheme: dark)").matches);
        // Remove listener
        return () => {
            window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", changeDarkMode);
        };
    }, [changeDarkMode, getDarkMode]);

    const [darkMode, setDarkMode] = React.useState(getDarkMode());

    return (
        <Suspense fallback={<Loader2 />}>
            <QueryClientProvider client={queryClient} contextSharing={true}>
                <RecoilRoot>
                    <Router>
                        <Wrapper>
                            <div>
                                <Routes>
                                    {/* <Route path="/" element={<Dashboard />}               /> */}

                                    <Route path="/magic/:code" element={<MagicLinkPage />} />
                                    <Route path="/forgot-password" element={<ForgotPassword />} />
                                    <Route
                                        path="/reset-password/:userId/:verificationToken"
                                        element={<ResetPassword />}
                                    />
                                    <Route path="/login" element={<Login />} />
                                    <Route path="/login/*" element={<Login />} />
                                    <Route path="/logout" element={<Logout />} />
                                    <Route path="/version" element={<VersionPage />} />
                                    <Route path="/sign-up/:processflowGroup" element={<SignUp />} />
                                    <Route path="/sign-up/:processflowGroup/:utm" element={<SignUp />} />
                                    <Route
                                        path="/sign-up/:processflowGroup/:ignore/*"
                                        element={<SignUp utmInQuery={true} />}
                                    />
                                    <Route path="/sign-up" element={<SignUp />} />
                                    <Route
                                        path="/setup-account/:userId/:verificationToken"
                                        element={<SetupAccountPage />}
                                    />
                                    <Route path="/" element={<Login />} />
                                    <Route
                                        path="/*"
                                        element={<ClearERC darkMode={darkMode} setDarkMode={setDarkMode} />}
                                    />
                                </Routes>
                                {/* </QueryClientProvider> */}
                            </div>
                        </Wrapper>
                        <ReactQueryDevtools initialIsOpen={false} />
                    </Router>
                </RecoilRoot>
            </QueryClientProvider>
        </Suspense>
    );
}

type ClearERCProps = {
    darkMode: boolean;
    setDarkMode: (darkMode: boolean) => void;
};

function ClearERC(props: ClearERCProps) {
    const [user, setUser] = useState(authService.getCurrentUser());
    const [adminCpaOrAffiliate, setAdminCpaOrAffiliate] = useState(false);
    const [sideBarOpen, setSideBarOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user === null) {
            navigate("/login");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);
    useEffect(() => {
        authService.canIAccess("ADMIN_DASHBOARD").then((res) => {
            if (res) {
                setAdminCpaOrAffiliate(res);
            }
        });
        authService.canIAccess("AFFILIATE").then((res) => {
            if (res) {
                setAdminCpaOrAffiliate(res);
            }
        });
        authService.canIAccess("ACCOUNTANT").then((res) => {
            if (res) {
                setAdminCpaOrAffiliate(res);
            }
        });
    }, [user]);

    if (!user) {
        navigate("/login");
        return <></>;
    }
    function adminMenu(
        sideBarOpen: boolean,
        setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>,
        darkMode: boolean,
        setDarkMode: (darkMode: boolean) => void,
    ) {
        return (
            <div className="text-gray-700 dark:text-gray-300 overflow-hidden ">
                <SideMenu
                    darkMode={darkMode}
                    setDarkMode={setDarkMode}
                    sideBarOpen={sideBarOpen}
                    setSideBarOpen={setSideBarOpen}
                >
                    <>
                        {/* <DashboardTop> */}
                        <DashboardHeader sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen}>
                            <div className="h-full">{mainRoutes(sideBarOpen, setSideBarOpen, darkMode)}</div>
                        </DashboardHeader>
                        {/* </DashboardTop> */}
                    </>
                </SideMenu>
            </div>
        );
    }

    function userMenu(
        sideBarOpen: boolean,
        setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>,
        darkMode: boolean,
    ) {
        return (
            <div className="text-gray-700 dark:text-gray-300">
                {/* <SideMenu darkMode={darkMode} setDarkMode={setDarkMode} sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} > */}
                <>
                    <DashboardTop>
                        {/* <DashboardHeader sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} > */}
                        {/* <div className="p-5 rounded-t-xl     bg-gray-900 h-full fixed w-full -z-10"> */}
                        {/* </div> */}
                        <div className="h-full">{mainRoutes(sideBarOpen, setSideBarOpen, darkMode)}</div>
                        {/* </DashboardHeader> */}
                    </DashboardTop>
                </>
                {/* </SideMenu> */}
            </div>
        );
    }

    function mainRoutes(
        sideBarOpen: boolean,
        setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>,
        darkMode: boolean,
    ) {
        return (
            <Routes>
                <Route
                    path="/dashboard"
                    element={
                        <Dashboard sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} darkMode={darkMode} />
                    }
                />
                <Route path="/" element={<Login />} />
                <Route path="/processflow/:processflowGroup" element={<ProcessFlowAdmin />} />
                <Route path="/processflow-categories" element={<ProcessFlowGroup />} />
                {/* <Route path="/processflow-wizard/:processflowGroup/:step" element={<ProcessFlowWizard />} /> */}

                <Route path="/processflow-wizard/:processflowGroup" element={<WizardContainer />} />
                <Route path="/processflow-wizard/:processflowGroup/:step" element={<WizardContainer />} />
                {/* <Route path="/processflow-wizard/:processflow" element={<ProcessFlowWizard />} /> */}

                <Route path="/processflow-wizard" element={<ProcessFlowWizard />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:userId/application" element={<UserApplicationPage />} />
                <Route path="/roles" element={<Roles />} />
                <Route path="/rule-picker/step/:step" element={<Roles />} />
                <Route path="/role-groups" element={<RoleGroups />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/affiliates" element={<Affiliates />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/admin/files" element={<Files />} />
                <Route path="/menu-admin" element={<MenuAdmin />} />
                <Route path="/rules" element={<ListProducts />} />
                <Route path="/default-users" element={<DefaultUsers />} />
                <Route path="/rules/:id" element={<Product />} />
                <Route path="/applications/:id" element={<Application />} />
                <Route path="/applications" element={<Application />} />
                <Route path="/my-files" element={<MyFiles />} />
                <Route path="/billing" element={<BillingPage />} />
                <Route path="/customer-card/:id" element={<CustomerCard2 />} />
                {/* <Route path="/customer-card/:id" element={<CustomerCard />} /> */}
                <Route path="/messages" element={<Messages />} />
                <Route path="/messages/:id" element={<Messages />} />
                <Route path="/message-types" element={<MessageTypes />} />
                <Route path="/my-files/:id" element={<MyFiles />} />
                <Route path="/utm-codes" element={<UTM />} />
                <Route path="/utm-logs" element={<UTMLogs />} />
                <Route path="/help" element={<Help />} />
                <Route path="/faq/new" element={<AddFaq />} />
                <Route path="/faq/edit/:id" element={<AddFaq />} />
                <Route path="/map" element={<MapDemo />} />
                <Route path="/utm" element={<UTM />} />
                <Route path="/i18n" element={<I18NextPage />} />
                <Route path="/faq/:term" element={<Faq />} />
                <Route path="/faq" element={<Faq />} />
                <Route path="/summary/:id" element={<CustomerCard2 />} />
                {/* <Route path="/sunday-funday" element={<UseNavigateProcessWrapper />} /> */}
                <Route path="/new-application" element={<NewApplication />} />
                <Route path="/pfc-tests" element={<Testing />} />
            </Routes>
        );
    }

    return (
        <React.StrictMode>
            <ClickToComponent />
            <QueryClientProvider client={queryClient} contextSharing={true}>
                <UserContext.Provider value={[user.user, setUser]}>
                    <div>
                        {adminCpaOrAffiliate
                            ? adminMenu(sideBarOpen, setSideBarOpen, props.darkMode, props.setDarkMode)
                            : userMenu(sideBarOpen, setSideBarOpen, props.darkMode)}
                    </div>
                </UserContext.Provider>
            </QueryClientProvider>
        </React.StrictMode>
    );
}

export default App;
