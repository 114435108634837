import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { z } from "zod";
import userAtom from "../../atoms/userAtom";
import { EmailField } from "../../layout/form/email-field";
import { Form, FormModalButtons } from "../../layout/form/form";
import { NumberField } from "../../layout/form/number-field";
import { PasswordField } from "../../layout/form/password-field";
import { TextField } from "../../layout/form/text-field";
import { useForm } from "../../hooks/useForm";
import ButtonNeoGen from "../../layout/button";
import ErrorSection from "../../layout/error-section";
import Loader2 from "../../sections/utilities/Loader2";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import authService from "../../services/auth.service";
import { actionMagicLink } from "../actions/action-magic-link";
import { getMagicLinkByCode } from "../actions/get-magic-link-by-code";

const schema = z.object({
    firstName: z.string(),
    lastName: z.string(),
    email: z.string(),
    phone: z.string(),
    password: z.string().min(8),
    w2Employees2020: z.string().optional(),
    w2Employees2021: z.string().optional(),
});

type Data = z.infer<typeof schema>;

export const MagicLinkPage = () => {
    const [loggedInUser, setUser] = useRecoilState(userAtom);
    const authToken = getAuthTokenNoThrow() || "no-token";
    const { code } = useParams();
    const form = useForm({ schema });
    const navigate = useNavigate();

    const query = useQuery(["magic-link"], async () => {
        const magicLink = code ? await getMagicLinkByCode({ authToken, code }) : undefined;
        return magicLink;
    });
    const magicLink = query.data;

    useEffect(() => {
        if (magicLink) {
            Object.keys(magicLink.data).forEach((k) => {
                form.resetField(k as any, { defaultValue: magicLink.data[k] });
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [magicLink]);

    const mutation = useMutation({
        mutationFn: async (data: Record<string, any>) => {
            if (!code) {
                throw new Error("MagicLink code must be defined.");
            }
            const result = await actionMagicLink({ authToken, code, data: data });
            return result;
        },
    });

    const handleSubmit = async (data: Data) => {
        const result = await mutation.mutateAsync(data);
        if (result.user) {
            setUser(result.user);
        }
        await authService.login(data.email, data.password);
    };

    const hasLoaded = !!magicLink;
    const hasSuccess = !!mutation.data?.magicLink;

    const handleContinue = () => {
        navigate("/dashboard");
    };

    return (
        <div className="flex justify-center align-middle">
            {!hasLoaded ? (
                <Loader2 />
            ) : (
                <>
                    <div className="p-8 bg-white rounded-xl m-10 w-[550px]">
                        {hasSuccess ? (
                            <>
                                <h1 className="text-center">Your ClearERC account has been created</h1>
                                <div className="mt-8">
                                    <ButtonNeoGen type="submit" className="w-full mt-6" onClick={handleContinue}>
                                        Continue to your ClearERC Dashboard
                                    </ButtonNeoGen>
                                </div>
                            </>
                        ) : (
                            <>
                                {magicLink.usedAt ? (
                                    <>
                                        <div style={{ textAlign: "center", fontSize: 20 }}>
                                            This magic link has already been used.
                                        </div>
                                        <div style={{ textAlign: "center", marginTop: 20 }}>
                                            Please try again or contact us for help.
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h1 className="text-center">Set up your ClearERC account</h1>
                                        <div className="flex justify-center mt-4">
                                            <p className="text-gray-400 text-sm w-[75%] text-center">
                                                Please enter and confirm your details below to set up your ClearERC
                                                account
                                            </p>
                                        </div>
                                        <div className="mt-8">
                                            <>
                                                {mutation.error && (
                                                    <ErrorSection
                                                        errors={[
                                                            (mutation.error as any)?.message || "Something went wrong",
                                                        ]}
                                                    />
                                                )}
                                                <Form onSubmit={form.handleSubmit(handleSubmit)}>
                                                    <TextField
                                                        label="First Name"
                                                        {...form.getFieldProps("firstName")}
                                                        isRequired
                                                    />
                                                    <TextField
                                                        label="Last Name"
                                                        {...form.getFieldProps("lastName")}
                                                        isRequired
                                                    />
                                                    <EmailField
                                                        autoComplete="username"
                                                        label="Email Address"
                                                        {...form.getFieldProps("email")}
                                                        isRequired
                                                    />
                                                    <TextField
                                                        label="Phone Number"
                                                        {...form.getFieldProps("phone")}
                                                        isRequired
                                                    />
                                                    <PasswordField
                                                        autoComplete="new-password"
                                                        label="Password"
                                                        {...form.getFieldProps("password")}
                                                        isRequired
                                                    />
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            flex: 1,
                                                            justifyContent: "space-between",
                                                            gap: 20,
                                                        }}
                                                    >
                                                        <div style={{ flex: 1 }}>
                                                            <TextField
                                                                label="Estimated W2 Employees 2020"
                                                                {...form.getFieldProps("w2Employees2020")}
                                                            />
                                                        </div>
                                                        <div style={{ flex: 1 }}>
                                                            <TextField
                                                                label="Estimated W2 Employees 2021"
                                                                {...form.getFieldProps("w2Employees2021")}
                                                            />
                                                        </div>
                                                    </div>
                                                    <ButtonNeoGen
                                                        type="submit"
                                                        className="w-full mt-6"
                                                        disabled={mutation.isLoading}
                                                    >
                                                        {mutation.isLoading ? "Loading..." : "Continue"}
                                                    </ButtonNeoGen>
                                                </Form>
                                            </>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};
