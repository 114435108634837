/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/26/20, 8:47 PM
 *
 */

import { useEffect, useState } from "react";
import Loader2 from "../../../utilities/Loader2";
import ModalDialog from "../../../../layout/modal-dialog";
import SelectNeoGen from "../../../../layout/select-neogen";
import { NeogenRoles, User } from "../../../../typings/api";
import InputControlled from "../../../../layout/input-controlled";
import PrintPre from "../../../../layout/print-pre";
import { ErcUser } from "../../../../typings/api/erc-user";
import { useQuery } from "@tanstack/react-query";
import usersService from "../../../../services/users.service";
import roleGroupService, { roleAssignments, roleGroupTypes } from "../../../../services/role-group.service";
import { RoleGroup } from "../../../../typings/api/role-group";
import { RoleGroupEntry } from "../../../../typings/api/role-group-entry";
import authService from "../../../../services/auth.service";

function compare(a: User, b: User) {
    if (a.email.toLowerCase() < b.email.toLowerCase()) {
        return -1;
    }
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
        return 1;
    }
    return 0;
}

function AddEditUserModal(props: AddEditUserModalProps) {
    // const [roles, setRoles] = useState(props.roles);
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [stPass, setStPass] = useState("");
    const [stUrl, setStUrl] = useState("");
    const [stCompany, setStCompany] = useState(-1);
    const [company, setCompany] = useState(-1);
    const [cpa, setCpa] = useState("");
    const [affiliate, setAffiliate] = useState("");
    const [taxAttorney, setTaxAttorney] = useState("");
    const [manager, setManager] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [roleGroup, setRoleGroup] = useState<RoleGroup | undefined>();

    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data.sort(compare);
        }
    });

    const roleGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        return response?.data || [];
    });

    const allRoleGroups = roleGroupsQuery.data || [];
    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const roleGroups = allRoleGroups.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroupTypes.SuperUser);

    useEffect(() => {
        if (!props.isEdit) {
            setEmail("");
            setName("");
            setCompany(-1);
        } else {
            if (props.userBeingEdited) {
                setEmail(props.userBeingEdited.email ?? "");
                setName(props.userBeingEdited.name ?? "");
                setFirstName(props.userBeingEdited.firstName ?? "");
                setLastName(props.userBeingEdited.lastName ?? "");
                setPhone(props.userBeingEdited.phone ?? "");
                setStPass(props.userBeingEdited.stPass ?? "");
                setStUrl(props.userBeingEdited.stUrl ?? "");
                setStCompany(props.userBeingEdited.stCompany ?? -1);
                setCompany(props.userBeingEdited.company ?? -1);
                setCpa(props.userBeingEdited.cpaUserId ?? "");
                setAffiliate(props.userBeingEdited.affiliateUserId ?? "");
                setManager(props.userBeingEdited.accountManagerUserId ?? "");
            }
        }
    }, [props.isEdit, props.userBeingEdited]);

    function saveUser() {
        props.saveUser(
            props.isEdit,
            email,
            name,
            company,
            firstName,
            lastName,
            phone,
            props.userBeingEdited,
            stPass,
            stUrl,
            stCompany,
            password,
            cpa,
            manager,
            affiliate,
            taxAttorney,
            roleGroup,
        );
    }

    if (usersQuery.isLoading) {
        // Not possible because it will be cached by the users page
        return <Loader2 />;
    }

    return (
        <>
            <ModalDialog
                size="md"
                okText="Save"
                okAction={() => saveUser()}
                show={props.show}
                close={() => props.handleClose(false)}
                title={
                    props.isEdit
                        ? props.userBeingEdited === null
                            ? "Loading..."
                            : "Editing: " + props.userBeingEdited.email
                        : "New User"
                }
            >
                <div className="mt-2">
                    {!((props.userBeingEdited === null && props.isEdit) || props.saving) ? (
                        <div>
                            <InputControlled
                                label="Email"
                                type="text"
                                value={email}
                                setValue={(e) => setEmail(e)}
                                placeholder="you@example.com"
                            />

                            {!props.isEdit && (
                                <>
                                    <InputControlled
                                        label="Password"
                                        type="password"
                                        value={password}
                                        setValue={(e) => setPassword(e)}
                                        placeholder="Password"
                                        className="mt-2"
                                        autoComplete="new-password"
                                    />
                                    <InputControlled
                                        label="Password again"
                                        type="password"
                                        value={passwordAgain}
                                        setValue={(e) => setPasswordAgain(e)}
                                        placeholder="Password again"
                                        className="mt-2"
                                        autoComplete="new-password"
                                    />
                                </>
                            )}
                            <div className="mt-5">
                                <InputControlled
                                    label="Company name"
                                    type="text"
                                    value={name}
                                    setValue={(e) => setName(e)}
                                    placeholder="Company name"
                                />
                            </div>
                            <div className="mt-5">
                                <InputControlled
                                    label="First name"
                                    type="text"
                                    value={firstName}
                                    setValue={(e) => setFirstName(e)}
                                    placeholder="First name"
                                />
                            </div>
                            <div className="mt-5">
                                <InputControlled
                                    label="Last name"
                                    type="text"
                                    value={lastName}
                                    setValue={(e) => setLastName(e)}
                                    placeholder="Last name"
                                />
                            </div>
                            <div className="mt-5">
                                <InputControlled
                                    label="Phone"
                                    type="text"
                                    value={phone}
                                    setValue={(e) => setPhone(e)}
                                    placeholder="Phone"
                                />
                            </div>

                            {!props.isEdit && (
                                <div className="my-5">
                                    <SelectNeoGen
                                        options={roleGroups.map((roleGroup) => ({
                                            id: roleGroup.id,
                                            name: roleGroup.name,
                                        }))}
                                        label="Role"
                                        value={roleGroup?.id}
                                        onChange={(roleGroupId) => {
                                            const roleGroupToSet = roleGroups.find(
                                                (roleGroup) => roleGroup.id === roleGroupId,
                                            );
                                            if (!roleGroupToSet) {
                                                throw new Error(`Role group with id ${roleGroupId} not found`);
                                            }
                                            setRoleGroup(roleGroupToSet);
                                        }}
                                    />
                                </div>
                            )}

                            {/* <div className="my-5">
                                <SelectNeoGen
                                    options={props.companiesQuery.data}
                                    label="Company"
                                    value={company}
                                    onChange={(e) => {
                                        setCompany(Number(e));
                                    }}
                                />
                            </div> */}
                            <div className="my-5">
                                <SelectNeoGen
                                    options={(usersQuery.data || []).map((user) => ({
                                        id: user.id,
                                        name: `${[user.firstName, user.lastName].join(" ")} (${user.name || "-"})`,
                                    }))}
                                    label="Affiliate"
                                    value={affiliate}
                                    onChange={(e) => {
                                        setAffiliate(e.toString());
                                    }}
                                />
                            </div>
                            {isSuperUser && (
                                <>
                                    <div className="my-5">
                                        <SelectNeoGen
                                            options={(usersQuery.data || []).map((user) => ({
                                                id: user.id,
                                                name: `${[user.firstName, user.lastName].join(" ")} (${
                                                    user.name || "-"
                                                })`,
                                            }))}
                                            label="Tax Attorney"
                                            value={manager}
                                            onChange={(e) => {
                                                setTaxAttorney(e.toString());
                                            }}
                                        />
                                    </div>
                                    <div className="my-5">
                                        <SelectNeoGen
                                            options={(usersQuery.data || []).map((user) => ({
                                                id: user.id,
                                                name: `${[user.firstName, user.lastName].join(" ")} (${
                                                    user.name || "-"
                                                })`,
                                            }))}
                                            label="Accountant"
                                            value={cpa}
                                            onChange={(e) => {
                                                setCpa(e.toString());
                                            }}
                                        />
                                    </div>
                                    <div className="my-5">
                                        <SelectNeoGen
                                            options={(usersQuery.data || []).map((user) => ({
                                                id: user.id,
                                                name: `${[user.firstName, user.lastName].join(" ")} (${
                                                    user.name || "-"
                                                })`,
                                            }))}
                                            label="Doc collector"
                                            value={manager}
                                            onChange={(e) => {
                                                setManager(e.toString());
                                            }}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className={"mt-5 pt-5 mb-5 pb-5 text-center"}>
                            <Loader2 />
                        </div>
                    )}
                </div>
            </ModalDialog>
        </>
    );
}

type AddEditUserModalProps = {
    handleClose: (x: boolean) => void;
    show: boolean;
    userBeingEdited: ErcUser | null;
    // roles: NeogenRoles[],
    // allRoles: any,
    setRoles: any;
    saveUser: (
        isEdit: boolean,
        email: string,
        name: string,
        company: number,
        firstName: string,
        lastName: string,
        phone: string,
        userBeingEdited: ErcUser | null,
        stPass: string,
        stUrl: string,
        stCompany: number,
        password: string,
        cpa: string,
        manager: string,
        affiliate: string,
        taxAttorney: string,
        roleGroup?: RoleGroup,
    ) => void;
    saving: boolean;
    companiesQuery: any;
    isEdit: boolean;
};

export default AddEditUserModal;
