import { useQueryClient } from "@tanstack/react-query";
import Swal from "sweetalert2";
import processflowService from "../../../services/processflow.service";
import { BusinessRuleGroup } from "../../../typings/api/business-rule-group";
import { Entry } from "../../../layout/add-to-list";
import Badge from "../../../layout/badge";

import ButtonNeoGen from "../../../layout/button";
import PrintPre from "../../../layout/print-pre";
import useFieldChanged from "../hooks/use-field-changed";
import { useRecoilState } from "recoil";
import processflowProgressIdAtom from "../../../atoms/processflowProgressIdAtom";
import userAtom from "../../../atoms/userAtom";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";

export function StepEntry({
    entry,
    entries,
    setEntries,
    setIsStep,
    // idx,
    setStepIsEdit,
    setId,
    setShow,
    // key,
    setShowEditRules,
}: {
    entry: any;
    entries: any[];
    setEntries: any;
    setIsStep: (isStep: boolean) => void;
    // idx: number,
    setStepIsEdit: (isEdit: boolean) => void;
    setId: (id: number) => void;
    setShow: (show: boolean) => void;
    key: any;
    setShowEditRules: (show: boolean) => void;
}): JSX.Element {
    const cache = useQueryClient();
    const [user, setUser] = useRecoilState<ClearERCUser>(userAtom);
    // const fieldChanged = useFieldChanged(user.id, processFlowGroup);

    // const [entry, setEntry] = useState<Entry>();
    return (
        <div
            className={
                "flex-grow break-inside-avoid mb-5      relative rounded-lg border-2 border-white " +
                "bg-slate-100 dark:bg-slate-800 dark:border-gray-700 px-6 py-2 shadow-md space-x-3" +
                "hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 " +
                "grid grid-cols-7"
            }
        >
            <></>
            {/* {
            <PrintPre>
                {entry}
            </PrintPre>
        } */}
            {/* <div className="flex-shrink-0">
            <span className={entry.icon} />
        </div> */}
            <div className="col-span-3 my-auto">
                {/* <span> */}
                {/* <PrintPre>{entry}</PrintPre> */}
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="">
                    <span className=" font-medium text-gray-900 dark:text-green-500 my-auto py-auto">
                        {entry.title}
                    </span>
                    <span className="ml-3 text-xs text-blue-400 ">(ID: {entry.id})</span>
                </p>
                {entry.businessRuleGroups.length > 0 && (
                    <div className="my-0">
                        {entry.businessRuleGroups.map((bg: BusinessRuleGroup) => {
                            return (
                                <div
                                    key={bg.id}
                                    className="text-sm my-1  dark:border-gray-600  dark:text-gray-400 inline-block mr-3"
                                >
                                    {/* <span className="mr-3">Rules:</span> */}

                                    <Badge color={bg.isQualify ? "green" : "red"} label={bg.name} />
                                </div>
                            );
                        })}
                    </div>
                )}
                {/* <p className="text-sm text-gray-500 ">Order: {entry.order}</p> */}
                {/* <p className={"text-sm " + (entry.required ? "text-red-500 " : "text-gray-500")}>{entry.required ? "Required" : "Optional"}</p> */}
                {/* </span> */}
            </div>
            <div className="col-span-2 my-auto text-sm text-gray-500 dark:text-gray-400">
                Stage: {entry.stageName}
                <Badge
                    color={entry.ruleGroupsAreOr ? "purple" : "green"}
                    label={entry.ruleGroupsAreOr ? "OR" : "AND"}
                />
                {/* <PrintPre>{entry.ruleGroupsAreOr?"And":"Or"}</PrintPre> */}
            </div>
            <div className="col-span-2 grid grid-cols-3 gap-5">
                <ButtonNeoGen
                    text={"Edit"}
                    onClick={() => {
                        setStepIsEdit(true);
                        setId(entry.id);
                        setShow(true);
                    }}
                    icon={"fas fa-edit"}
                    size={"xs"}
                    className={
                        "bg-indigo-500 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded z-10 mx-auto my-auto px-auto py-auto"
                    }
                />
                <ButtonNeoGen
                    text={"Rules"}
                    onClick={() => {
                        // alert("Edit " + id);
                        // setStepIsEdit(true);
                        // alert("1");
                        setIsStep(true);

                        setId(entry.id);
                        setShowEditRules(true);
                    }}
                    icon={"fas fa-shield-alt"}
                    size={"xs"}
                    type={"info"}
                    className={"z-10 mx-auto my-auto px-auto py-auto"}
                />

                <ButtonNeoGen
                    text={"Delete"}
                    onClick={() => {
                        Swal.fire({
                            title: "Are you sure you want to delete this step?",
                            text: "You won't be able to revert this!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonColor: "#3085d6",
                            cancelButtonColor: "#d33",
                            confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                // TODO - delete associated business rules
                                processflowService.deleteByID(entry.id).then(() => {
                                    cache.invalidateQueries(["processflow-entries"]);
                                    setEntries(
                                        entries?.filter((_entry: Entry, _index: number) => {
                                            return entry.id !== _entry.id;
                                        }) ?? [],
                                    );
                                });
                            }
                        });
                    }}
                    type={"danger"}
                    size={"xs"}
                    icon={"fas fa-trash-alt"}
                    className={
                        "bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded z-10  mx-auto my-auto px-auto py-auto"
                    }
                />
            </div>
        </div>
    );
}
