import { FormEventHandler, ReactNode } from "react";
import { UseFormReturn } from "react-hook-form";
import ButtonNeoGen from "../button";

export const Form = ({
    children,
    error,
    onSubmit,
}: {
    children: ReactNode;
    error?: Error;
    onSubmit: FormEventHandler<HTMLFormElement>;
}) => {
    return (
        <div>
            {error && <div>Something went wrong: {error.message}</div>}
            <form
                onSubmit={(e) => {
                    onSubmit(e);
                }}
            >
                {children}
            </form>
        </div>
    );
};

export const FormModalButtons = ({
    onCancel,
    form,
    cancelText,
    okText,
    isLoading,
}: {
    onCancel: () => any;
    form: UseFormReturn<any>;
    cancelText?: string;
    okText?: string;
    isLoading?: boolean;
}) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 6,
                marginTop: 20,
            }}
        >
            <button
                type="button"
                className="py-2 px-4  sx:mt-3 ring-0  rounded-md border border-gray-300 dark:border-gray-600 shadow-sm dark:bg-gray-800  bg-white text-base font-medium text-gray-700 dark:text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-1 focus:ring-offset-0 focus:ring-indigo-500 sm:mt-0  sm:text-sm"
                onClick={() => onCancel()}
            >
                {cancelText ?? "Cancel"}
            </button>
            <ButtonNeoGen type="submit" disabled={!form.formState.isDirty || isLoading}>
                {isLoading ? "Loading..." : okText ?? "Save"}
            </ButtonNeoGen>
        </div>
    );
};
