import { useQuery } from "@tanstack/react-query";
import businessRuleGroupService from "../../../services/business-rule-group.service";
import processflowStageService from "../../../services/processflow-stage.service";
import processflowService from "../../../services/processflow.service";
import { ProcessFlow } from "../../../typings/api/processflow";
import { ProcessFlowStage } from "../../../typings/api/processflow-stage";
import ModalDialog from "../../../layout/modal-dialog";
import PrintPre from "../../../layout/print-pre";
import TableNeogen from "../../../layout/table-neogen";
import Loader2 from "../../utilities/Loader2";
import { useParams } from "react-router-dom";
import processflowGroupService from "../../../services/processflow-group.service";
import ProgressBar from "../../../layout/progress-bar";
import { useState, useEffect, useCallback } from "react";

/**
 * A React component that renders a modal for editing a process flow group.
 * @param {DuplicateGroupProps} props - The props for the component.
 * @returns A React component that renders a modal for editing a process flow group.
 */
export default function DuplicateGroup(props: DuplicateGroupProps) {
    const { id } = useParams<{ id: string }>();
    const [progressText, setProgressText] = useState("Loading...");
    const [duplicating, setDuplicating] = useState(false);

    // Get the processflow group and related items
    const groupQuery = useQuery(["processflow-groups", id], async () => {
        const response = await processflowGroupService.getOneIncludingRequiredRoles(Number(id));
        if (response) {
            return response.data;
        }
    });

    const stageQuery = useQuery(["processflow-stages", "getAllByGroupId", id], async () => {
        const response = await processflowStageService.getAllByGroupIdIncludingRuleGroups(Number(id));
        if (response) {
            return response.data?.map((stage: ProcessFlowStage) => {
                return {
                    id: stage.id,
                    name: stage.name,
                    description: stage.description,
                    order: stage.order,
                    status: stage.status,
                    current: stage.current,
                    group: stage.group,
                    isPublic: stage.isPublic,
                    businessRuleGroups: stage.businessRuleGroups ?? [],
                };
            });
        }
    });

    const entriesQuery = useQuery(
        ["processflow-entries", "getAllByGroupId", id],
        async () => {
            const response = await processflowService.getAllByGroupId(Number(id));
            if (response) {
                // alert(JSON.stringify(response.data));
                const mapped = response.data
                    .map((entry: ProcessFlow) => {
                        const newEntry: any = {};
                        newEntry.id = entry.id;
                        newEntry.title = entry.title ?? "";
                        newEntry.businessRuleGroups = entry.businessRuleGroups ?? [];
                        newEntry.order = entry.order;
                        newEntry.stageName =
                            stageQuery.data?.find((stage: ProcessFlowStage) => stage.id === entry.stage)?.name ??
                            "Unknown";
                        return Object.assign(entry, newEntry);
                        // return newEntry;
                    })
                    .sort((a, b) => a.order - b.order);
                // alert(JSON.stringify(mapped));
                return mapped;
            } else {
                console.error(response);
            }
        },
        { enabled: stageQuery.isSuccess },
    );

    const rulesAndGroupsQuery = useQuery(["business-rule-groups"], async () => {
        const response = await businessRuleGroupService.getByGroupIncludingChildren(Number(id));
        if (response) {
            return response.data;
        }
    });

    const [percentDone, setPercentDone] = useState(0);

    const startDuplicating = useCallback(async () => {
        if (groupQuery.isLoading || stageQuery.isLoading || entriesQuery.isLoading || rulesAndGroupsQuery.isLoading) {
            return;
        }
        setPercentDone(0);
        setProgressText("Duplicating process flow group...");
        setPercentDone(10);
        await new Promise((r) => setTimeout(r, 2000));
        setProgressText("Duplicating processflow groups...");
        for (const group of groupQuery.data ?? []) {
            // await businessRuleGroupService.create(group);
            await new Promise((r) => setTimeout(r, 2000));
            setPercentDone((c) => c + Math.round(20 / (groupQuery.data?.length ?? 1)));
            setProgressText(`Duplicating processflow group ${group.name}...`);
        }
        for (const group of rulesAndGroupsQuery.data ?? []) {
            // await businessRuleGroupService.create(group);
            await new Promise((r) => setTimeout(r, 2000));
            setPercentDone((c) => c + Math.round(20 / (rulesAndGroupsQuery.data?.length ?? 1)));
            setProgressText(`Duplicating rules and group ${group.name}...`);
        }
        for (const group of stageQuery.data ?? []) {
            // await businessRuleGroupService.create(group);
            await new Promise((r) => setTimeout(r, 2000));
            setPercentDone((c) => c + Math.round(20 / (stageQuery.data?.length ?? 1)));
            setProgressText(`Duplicating stage ${group.name}...`);
        }
        for (const group of entriesQuery.data ?? []) {
            // await businessRuleGroupService.create(group);
            await new Promise((r) => setTimeout(r, 2000));
            setPercentDone((c) => c + Math.round(20 / (entriesQuery.data?.length ?? 1)));
            setProgressText(`Duplicating entry ${group.name}...`);
        }
    }, [
        entriesQuery.data,
        entriesQuery.isLoading,
        groupQuery.data,
        groupQuery.isLoading,
        rulesAndGroupsQuery.data,
        rulesAndGroupsQuery.isLoading,
        stageQuery.data,
        stageQuery.isLoading,
    ]);

    useEffect(() => {
        if (
            !(groupQuery.isLoading || stageQuery.isLoading || entriesQuery.isLoading || rulesAndGroupsQuery.isLoading)
        ) {
            // we are ready to start
            if (!duplicating) {
                setDuplicating(true);
                startDuplicating();
            }
            // startDuplicating();
        } else {
            // we are not ready to start
            setDuplicating(false);
            setPercentDone(0);
            let text = "Waiting on ";
            if (groupQuery.isLoading) {
                text += "processflow groups, ";
            }
            if (stageQuery.isLoading) {
                text += "processflow stages, ";
            }
            if (entriesQuery.isLoading) {
                text += "processflow entries, ";
            }
            if (rulesAndGroupsQuery.isLoading) {
                text += "business rule groups, ";
            }
            setProgressText(text.substring(0, text.length - 2) + "...");
        }
    }, [
        duplicating,
        entriesQuery.isLoading,
        groupQuery.data,
        groupQuery.isLoading,
        percentDone,
        rulesAndGroupsQuery.isLoading,
        stageQuery.isLoading,
        startDuplicating,
    ]);

    return (
        <ModalDialog
            close={props.close}
            size="md"
            show={props.show}
            title={"Duplicate Process Flow and Associated Items"}
        >
            {groupQuery.isLoading || stageQuery.isLoading || entriesQuery.isLoading || rulesAndGroupsQuery.isLoading ? (
                <Loader2 />
            ) : (
                <>
                    {/* <PrintPre> */}
                    <ProgressBar percentDone={percentDone} additionalText={progressText} />
                    {/* <TableNeogen entries = {
                    groupQuery.data??[]
                } />
                {
                    <TableNeogen entries = {
                        stageQuery.data??[]
                    } />
                    // stageQuery.data
                }
                {
                    <TableNeogen entries = {
                        entriesQuery.data??[]
                    } />
                    // entriesQuery.data
                }
                {
                    <TableNeogen entries = {
                        rulesAndGroupsQuery.data??[]
                    } />
                    // rulesAndGroupsQuery.data
                } */}
                    {/* </PrintPre> */}
                </>
            )}
        </ModalDialog>
    );
}

type DuplicateGroupProps = {
    show: boolean;
    close: () => void;
    // id: number,
    // groupId: number,
};
