import users from "../sections/admin/users/users";
import { ButtonNeoGenProps } from "./button";
import Filter from "./filter";
import PrintPre from "./print-pre";
import SelectNeoGen from "./select-neogen";

export default function PageDescription(props: PageDescriptionProps) {
    return (
        <main className={(props.doOffset === false ? "" : "-mt-0 ") + "p-0 my-2 pb-3" + " " + props.className}>
            <div className="w-full  px-0 xs:px-6 lg:max-w-full lg:px-0">
                {/* Main 3 column grid */}
                <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-1 ">
                    {/* Left column */}
                    <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                        {/* Welcome panel */}
                        <section aria-labelledby="profile-overview-title">
                            <div className="rounded-xl bg-white   shadow dark:bg-gray-800">
                                <div className="bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl p-6 dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow-sm">
                                    <>
                                        <div className="sm:flex sm:items-center sm:justify-between ">
                                            <div className="sm:flex-grow sm:space-x-5">
                                                <div className="mt-4  sm:mt-0 sm:pt-1 text-left sm:text-center font-medium text-gray-500 dark:text-green-400 md:flex">
                                                    {props.title && (
                                                        <>
                                                            <div className="sm:flex-auto dark:text-indigo-400">
                                                                <p className="text-left text-xl font-light tracking-wider text-gray-600 sm:text-2xl dark:text-green-400 ">
                                                                    {props.title}
                                                                </p>
                                                                {props.description && (
                                                                    <p className="mt-3 mb-3 tracking-wide text-left">
                                                                        {props.description}
                                                                    </p>
                                                                )}
                                                                {props.description2 && (
                                                                    <p className="mb-3 tracking-wide text-left">
                                                                        {props.description2}
                                                                    </p>
                                                                )}
                                                                {props.description3 && (
                                                                    <p className="mb-3 tracking-wide text-left">
                                                                        {props.description3}
                                                                    </p>
                                                                )}
                                                            </div>

                                                            {props.buttons?.map((button, index) => (
                                                                <div
                                                                    key={index}
                                                                    className="sm:flex-none inline-block md:mr-3 start md:my-0 my-3 "
                                                                >
                                                                    {button.label && !button.disabled && (
                                                                        <div className="sm:flex-auto ">
                                                                            <button
                                                                                type="button"
                                                                                className={
                                                                                    " inline-flex items-center justify-center rounded-md border" +
                                                                                    " border-transparent " +
                                                                                    (button.bg ?? "bg-indigo-600") +
                                                                                    " px-4 py-2 text-sm " +
                                                                                    "font-medium text-white dark:text-gray-300 drop-shadow-md hover:bg-indigo-700 " +
                                                                                    "focus:outline-none focus:ring-2 focus:ring-indigo-500 shadow-inner-lg" +
                                                                                    "focus:ring-offset-2 sm:w-auto"
                                                                                }
                                                                                // onClick={() => showAddEditUser(null)}
                                                                                onClick={button.onClick}
                                                                            >
                                                                                {button.icon && (
                                                                                    <span
                                                                                        className={
                                                                                            button.icon + " mr-2"
                                                                                        }
                                                                                    />
                                                                                )}

                                                                                {button.label}
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                        </>
                                                    )}

                                                    {props.children}
                                                </div>
                                            </div>
                                        </div>

                                        {props.filters?.map((filter) => {
                                            return (
                                                <div className="w-full block flex-auto self-end" key={filter.label}>
                                                    <Filter
                                                        onChange={filter.onChange}
                                                        label={filter.label}
                                                        options={filter.options}
                                                        value={filter.value}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </>
                                </div>

                                {/* <div className="border-t border-gray-200 bg-gray-50 dark:bg-gray-800 dark:border-gray-900 grid grid-cols-1 divide-y divide-gray-200 dark:divide-gray-900 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">

                                </div> */}
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </main>
    );
}
// type PageDescriptionButton = {
//     label?: string,
//     icon?: string,
//     onClick?: () => void,
//     disabled?: boolean,
//     bg?: string
// }

type Filter = {
    label: string;
    value: string;
    options: { id: string; name: string }[];
    onChange: (idx: number | string, text?: string) => void;
};

type PageDescriptionProps = {
    children?: any;
    doOffset?: boolean;
    className?: string;
    title?: string;
    description?: string;
    description2?: string;
    description3?: string;
    filters?: Filter[];
    buttons?: ButtonNeoGenProps[];
};
