import { ReactNode } from "react";
import { Label } from "../label";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

export type FieldProps = {
    label?: string;
    isRequired?: boolean;
    children: ReactNode;
    helperText?: string;
    errors?: { message?: string }[];
    placeholder?: string;
    isFocused?: boolean;
};

export type FormFieldProps<V> = Omit<FieldProps, "children"> & {
    defaultValue?: V;
};

export const Field = ({
    children,
    helpText,
    label,
    isRequired,
    error,
}: {
    children: ReactNode;
    helpText?: ReactNode;
    label: ReactNode;
    isRequired?: boolean;
    error?: string;
}) => {
    return (
        <div style={{ display: "flex", flexDirection: "column", marginBottom: 12, gap: 4, position: "relative" }}>
            {label && <Label isRequired={!!isRequired} text={label} />}
            {children}
            {error && (
                <p id="comments-description" className="text-red-400 text-sm">
                    {error}
                </p>
            )}
            {helpText && (
                <p id="comments-description" className="text-gray-400 text-sm">
                    {helpText}
                </p>
            )}
        </div>
    );
};
